import Container from '@layout/container';
import Image from '@components/image';
import PropTypes from 'prop-types';
import Link from '@components/link';

import truncate from 'lodash/truncate';

import styles from './styles.module.scss';
const { content, link } = styles;

const getRecordUrl = ({ type, path, category }) => {
  let recordUrl = '';
  let theme = 'background-is-white';

  // if external url, just return the external
  if (!path || path.match(/^http/)) return { recordUrl: path, theme: theme };
  // add fwd slash if none is on the path
  else if (path.match(/^/)) path = '/' + path;

  switch (type) {
    case 'PostBlog':
      recordUrl = '/blog';
      theme = 'background-is-gray';
      break;
    case 'TopicPage':
    case 'PageBuilderdevelopment':
      recordUrl = `/${category}`;
      break;
    case 'Community':
      recordUrl = `/community`;
      break;
    default:
      break;
  }

  return { recordUrl: recordUrl.toLowerCase() + path, theme: theme };
};

const FeaturedContent = (props) => {
  const {
    __typename,
    image,
    title,
    excerpt,
    linkUrl,
    linkLabel,
    parent,
    forceWhiteBackground = false
  } = props;

  let { recordUrl, theme } = getRecordUrl({ category: parent, path: linkUrl, type: __typename });
  theme = forceWhiteBackground ? 'background-is-white' : theme;

  return (
    <Container key={title} theme={theme} width="fixed" gridOptions={{ gutter: 50, layout: '2' }}>
      <div className={content}>
        <span>{title}</span>
        <p>{truncate(excerpt, { length: 256 })}</p>

        {recordUrl && linkUrl && (
          <Link url={recordUrl} ariaLabel={linkLabel || 'View More'} className={link}>
            {linkLabel || 'View More'}
          </Link>
        )}
      </div>
      <div>
        {image && (
          <Image
            url={recordUrl}
            image={{
              alt: image?.alt,
              height: 355,
              src: image?.src || image?.url,
              width: 519
            }}
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        )}
      </div>
    </Container>
  );
};

FeaturedContent.propTypes = {
  /**
   * Specifies the type of content that's featured
   * Used to populate url path, minor style changes
   */
  __typename: PropTypes.oneOf([
    'Community',
    'PostBlog',
    'TopicRecord',
    'TopicPage',
    'PageBuilderdevelopment'
  ]),

  /**
   * Specifies the author, if any
   */
  author: PropTypes.string,

  /**
   * Specifies the body
   */
  excerpt: PropTypes.string,

  /**
   * Forces white background - to prevent strange layout on blog page
   */
  forceWhiteBackground: PropTypes.bool,

  /**
   * Specifies the image src and alt text
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the link label
   */
  linkLabel: PropTypes.string,

  /**
   * Specifies the link url (prior to prepending parentPage if applicable)
   */
  linkUrl: PropTypes.string,

  /**
   * Specifies the parent page (category) if the input is a topic page
   */
  parent: PropTypes.string,

  /**
   * Specifies the date on which the content was published
   */
  publishDate: PropTypes.string,

  /**
   * Specifies the title prop
   */
  title: PropTypes.string
};

export default FeaturedContent;
