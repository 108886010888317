import PropTypes from 'prop-types';
import classNames from 'classnames';
import NextImage from 'next/image';

// -------------------------------------------------------

import Link from '@components/link';

// -------------------------------------------------------

import styles from './styles.module.scss';
const { background_image, img, layout_is_fill } = styles;

// -------------------------------------------------------

export const layoutOptions = {
  fill: 'layout_is_fill',
  fixed: 'layout_is_fixed',
  intrinsic: 'layout_is_intrinsic',
  responsive: 'layout_is_responsive'
};

// ---------------------------------------------------------

const NextImageComp = (props) => {
  if (!props.image || !props?.image?.src) return null;

  let {
    backgroundImage,
    className,
    image,
    layout,
    objectFit,
    objectPosition,
    priority,
    quality,
    url,
    unoptimized = false,
    sizes = '100vw'
  } = props;
  let { alt, height, src, width } = image;

  // normalize URLs for protocol-agnostic src's
  src = src?.slice(0, 2) === '//' ? `https:${src}` : src;

  // -------------------------------------------------------

  const layoutIsFill = layout === 'fill';

  let calculatedObjectFit = objectFit;
  if (!objectFit) {
    if (!backgroundImage) {
      calculatedObjectFit = 'cover';
    }
  }

  const nextImage = (
    <div
      className={classNames(img, className, {
        [layout_is_fill]: layoutIsFill
      })}
    >
      <NextImage
        alt={alt || ''}
        height={height}
        style={{ objectFit: calculatedObjectFit, objectPosition: objectPosition }}
        priority={priority}
        quality={quality}
        unoptimized={
          src.includes('sibcycline') ||
          src.includes('google') ||
          unoptimized ||
          process.env.STORYBOOK_ENV
        }
        src={src}
        sizes={sizes}
        width={width}
      />
    </div>
  );

  let nextImageWrapper = nextImage;

  if (backgroundImage) {
    nextImageWrapper = <div className={background_image}>{nextImage}</div>;
  } else if (layoutIsFill) {
    nextImageWrapper = (
      <div style={{ height: height, position: 'relative', width: width }}>{nextImage}</div>
    );
  }

  // ---------------------------------------------------------

  let imageComponent;

  if (url) {
    imageComponent = <Link url={url}>{nextImageWrapper}</Link>;
  } else {
    imageComponent = nextImageWrapper;
  }

  return imageComponent;
};

NextImageComp.propTypes = {
  /**
   * Specifies if the image is used as a background image
   */
  backgroundImage: PropTypes.bool,

  /**
   * Specifies the image object
   */
  image: PropTypes.shape({
    /**
     * Specifies the alt attribute
     */
    alt: PropTypes.string,

    /**
     * Specifies the height
     */
    height: PropTypes.number || PropTypes.string,

    /**
     * Specifies the image source
     */
    src: PropTypes.string,

    /**
     * Specifies the width
     */
    width: PropTypes.number || PropTypes.string
  }),

  /**
   * Specifies how the image should respond
   * https://nextjs.org/docs/api-reference/next/image#layout
   */
  layout: PropTypes.oneOf(Object.keys(layoutOptions)),

  /**
   * Specifies how the image is positioned within its parent element when using layout="fill"
   */
  objectFit: PropTypes.oneOf(['contain', 'cover', 'fill', 'none', 'scale-down']),

  /**
   * Specifies how the image is positioned within its parent element when using layout="fill"
   */
  objectPosition: PropTypes.string,

  /**
   * Specifies if the image is considered high priority and preloaded
   */
  priority: PropTypes.bool,

  /**
   * Specifies the quality of the optimized image, defaults to 75
   */
  quality: PropTypes.number,

  /**
   *  Provides information about how wide the image will be at different breakpoints
   */
  sizes: PropTypes.string,

  /**
   * Use of this prop is only for the use of local images on Storybook
   */
  unoptimized: PropTypes.bool,

  /**
   * Specifies the URL, if the image has a link
   */
  url: PropTypes.string
};

export default NextImageComp;
