import propertyTypeMap from '@src/data/property-type-map.json';
import { initialState } from '@src/store/slices/searchFiltersSlice';
import { locationKeys, ignoreEntries, operators, flattenAndTransform } from './constants';
/**
 * Compares two objects and returns the difference between the two
 */
export const compareObjects = (obj1, obj2) => {
  const _obj = Object.keys(obj1)
    .map((k) => {
      const v = obj1[k];
      return JSON.stringify(obj2[k]) === JSON.stringify(v) ? undefined : [k, v];
    })
    .filter((el) => el !== undefined);
  return Object.fromEntries(_obj);
};

export const querifyLocation = ({ areaType, location, state }) => {
  if (areaType === 'zip') {
    let arr = state.split(' ');
    state = arr[arr.length - 1];
  }

  if (areaType === 'counties') {
    const countyIndex = location.lastIndexOf(' County');
    location = countyIndex === -1 ? location : location.substring(0, countyIndex);
  }

  return `${location}_${state}`;
};

/**
 *
 * @param {String} propertyType
 * @param {Array} selectIds
 * @returns {Array} PropertyTypeIds that match with the input propertyType & selectIds.
 * Returns an empty array if no subTypes exist.
 */
export const selectIdsToSubTypeIds = (propertyType = 0, selectIdInput = []) => {
  const selectIds = coerceToArray(selectIdInput).map((el) => parseInt(el));
  const selectedType = propertyTypeMap.find((el) => {
    return el.propertyTypeId == propertyType;
  });

  if (!selectedType?.subTypes) return selectedType?.defaultSubTypeIds || [];

  const result = selectIds.reduce((acc, curr) => {
    const selectedSubType = selectedType?.subTypes?.find((el) => el.selectId === curr) || [];
    return [...acc, ...(selectedSubType?.subTypeIds || [])];
  }, []);
  return result;
};

export const idToPropertyTypeName = (propertyTypeId = 0) => {
  const selectedType = propertyTypeMap.find((el) => {
    return el.propertyTypeId == propertyTypeId;
  });

  return selectedType?.propertyType;
};

export const coerceToArray = (input) => {
  if (Array.isArray(input)) return input;
  else if (input) return [input];
  else return [];
};

export const getSortOrder = (order) => {
  if (!order || Object.keys(order).length < 1) {
    return;
  }

  if (order?.price === 'ASC') {
    return 'Lowest Price';
  } else if (order?.daysSinceLastPriceChange === 'ASC') {
    return 'Price Change';
  } else if (order?.hasInRangeOpenHouse === 'DESC') {
    return 'Open House Date';
  } else if (order?.daysSinceNew === 'ASC') {
    return 'Newest Listings';
  }
};

export const getSearchVariables = (variables) => {
  if (!variables) return [];
  const result = {};

  const recurVariables = (variables) => {
    if (!variables?.length) return;

    if (locationKeys.includes(Object.keys(variables[0])[0])) {
      const stateIndex = variables.findIndex((el) => {
        return el.state;
      });

      if (stateIndex === -1) return;

      const locIndex = stateIndex === 1 ? 0 : 1;
      const [locationType, { eq: locationValue }] = Object.entries(variables[locIndex])[0];
      const transformedLocType = operators[locationType] || locationType;
      const state = variables[stateIndex].state.eq;
      const locString = `${locationValue}_${state}`;

      result[transformedLocType]
        ? result[transformedLocType].push(locString)
        : (result[transformedLocType] = [locString]);
    } else {
      for (const item of variables) {
        if (!item) return;
        const key = Object.keys(item)[0];

        // Grab the nested locations from the query and adjust them to mimic the
        // existing URL values (Cincinnati_OH) in the respective arrays for their
        // location type
        if (locationKeys.includes(key)) {
          // Ensure entries with a key in the ignore array do not get added to the
          // query - which would result in a failing query
        } else if (ignoreEntries.includes(key)) {
          continue;
        } else if (key === 'or' || key === 'and') {
          recurVariables(item[key]);
        } else {
          const operator = Object.keys(item[key])[0];
          let value = item[key][operator];

          const transformedKey =
            (operators[key] && operators[key][operator]) || operators[key] || key;

          // avoid daysSinceNew picking the { lte: } object as a property key
          if (typeof transformedKey === 'object') break;

          if (transformedKey === 'featured') {
            value = true;
          }

          result[transformedKey] = value;
        }
      }
    }
  };

  recurVariables(variables);
  return result;
};

export const getResultsPageMeta = (vars) => {
  const defaultMeta = {
    description:
      'Use advanced searching and filtering options to find Real Estate & Homes for Sale in the Cincinnati, Dayton, NKY, & SE Indiana markets',
    header: 'Search Results',
    title: 'Real Estate Search Results'
  };
  const metaValues = { ...defaultMeta };

  const areaTypes = ['places', 'schoolDistricts', 'zip', 'counties'];

  const selectedLocations = [];
  let selectedAreaType = '';
  for (const type of areaTypes) {
    if (vars[type]) {
      if (Array.isArray(vars[type])) selectedLocations.push(...vars[type]);
      else selectedLocations.push(vars[type]);
      selectedAreaType = type;
    }
  }

  const selectedStatus = [];
  if (vars.daysSinceLastPriceChange) selectedStatus.push('Price Changes');
  if (vars.daysSinceNew) selectedStatus.push('New Listings');
  if (vars.showOnlyOpenHouses) selectedStatus.push('Open Houses');
  if (vars.showOnlyNewConstructions) selectedStatus.push('New Construction');
  if (vars.featured) selectedStatus.push('Featured');

  if (
    selectedLocations.length === 0 &&
    (selectedStatus.length === 1 ||
      (selectedStatus.length === 2 && selectedStatus.includes('Featured')))
  ) {
    let newHeader = 'Search Results';

    if (selectedStatus.length === 1) {
      newHeader = selectedStatus[0];
    } else if (selectedStatus.length === 2 && selectedStatus.includes('Featured')) {
      const nonFeaturedStatus = selectedStatus?.filter((el) => el !== 'Featured');
      newHeader = `Featured ${nonFeaturedStatus}`;
    }

    metaValues.header = newHeader;
    metaValues.title = newHeader;
  }

  if (selectedLocations.length === 1) {
    const [location, state] = selectedLocations[0].split('_');
    let newHeader = 'Real Estate in ';

    if (selectedStatus.length === 1) {
      newHeader = `${selectedStatus[0]} in `;
    } else if (selectedStatus.length === 2 && selectedStatus.includes('Featured')) {
      const nonFeaturedStatus = selectedStatus?.filter((el) => el !== 'Featured');
      newHeader = `Featured ${nonFeaturedStatus[0]} in `;
    }

    let suffix = '';
    switch (selectedAreaType) {
      case 'places':
        newHeader += `${location}, ${state}`;
        break;
      case 'counties':
        // add County after the county name if 'County' is not part of the name
        if (!location.match(/county/gi)) suffix = ' County';
        newHeader += `${location}${suffix}, ${state}`;
        break;
      case 'schoolDistricts':
        // add (SD) as a suffix if the location doesn't include 'School District'
        if (!location.match(/school district/gi)) suffix = ' (SD)';
        newHeader += `${location}${suffix}`;
        break;
      default:
        newHeader += `${location}`;
        break;
    }
    metaValues.header = newHeader;
    metaValues.title = newHeader;
  }

  if (metaValues.header.length >= 50) {
    metaValues.header = defaultMeta.header;
    metaValues.title = defaultMeta.title;
  }

  return metaValues;
};

export const pushStateToQuery = async (router, params, resetPage = false) => {
  const { paramsForQueryString } = await convertFromReduxParams(
    {
      ...params,
      ...(resetPage ? { page: 1 } : null)
    },
    router
  );

  const comparedObject = compareObjects(paramsForQueryString, initialState);

  router.push(
    {
      pathname: '/results',
      query: comparedObject
    },
    undefined,
    { shallow: false }
  );
};

// Converts Redux state to formats suitable for query strings and GraphQL queries.
export const convertFromReduxParams = async (
  stateInput,
  daysSinceNew,
  daysSinceLastPriceChange
) => {
  // Define a function for deep cloning objects to ensure no unintended side effects occur when modifying the clone.
  const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

  // Clone the input state to start processing without altering the original state.
  const paramsForQueryString = deepClone(stateInput);

  // Iterate over the cloned state, transforming properties based on predefined rules or mappings.
  Object.keys(paramsForQueryString).forEach((category) => {
    if (Object.keys(flattenAndTransform).includes(category)) {
      paramsForQueryString[category].forEach((property) => {
        // Apply transformation rules, defaulting to true if no specific transformation is found.
        paramsForQueryString[property] = flattenAndTransform[category][property] || true;
      });
      // Remove the original category from the parameters after transformation.
      delete paramsForQueryString[category];
    }
  });

  // Handle 'daysSinceNew' and 'daysSinceLastPriceChange' parameters separately from the rest.
  if (paramsForQueryString.daysSinceNew) {
    paramsForQueryString.daysSinceNew = parseInt(daysSinceNew) || 7;
  }

  if (paramsForQueryString.daysSinceLastPriceChange) {
    paramsForQueryString.daysSinceLastPriceChange = parseInt(daysSinceLastPriceChange) || 7;
  }

  const soldStatusSelected =
    paramsForQueryString.status?.includes('Sold') && paramsForQueryString.status.length === 1;

  if (!soldStatusSelected) {
    delete paramsForQueryString.daysSinceSold;
  }

  // Create another deep clone specifically for GraphQL query parameters.
  const paramsForGraphQL = deepClone(paramsForQueryString);

  // Process 'locations' parameters for the query string, transforming them into a specific format.
  (paramsForQueryString.locations || []).forEach(({ value }) => {
    const locationString = querifyLocation(value);
    // Organize location strings by their area type.
    if (paramsForQueryString[value.areaType]) {
      paramsForQueryString[value.areaType].push(locationString);
    } else {
      paramsForQueryString[value.areaType] = [locationString];
    }
  });

  // Handle conversion for property type IDs, transforming them into a format suitable for GraphQL queries.
  const { propertyTypeId, propertySubSelectIds } = paramsForGraphQL;
  paramsForGraphQL.propertySubTypeIds = selectIdsToSubTypeIds(propertyTypeId, propertySubSelectIds);

  // Ensure proper formatting of the propertyTypeId for GraphQL queries.
  if (!propertyTypeId) {
    delete paramsForGraphQL.propertyTypeId;
  } else {
    paramsForGraphQL.propertyTypeId = parseInt(propertyTypeId);
  }

  // Process 'locations' for GraphQL, similar to how it's done for query strings but potentially in a different format.
  (paramsForGraphQL.locations || []).forEach(({ value }) => {
    if (paramsForGraphQL[value.areaType]) {
      paramsForGraphQL[value.areaType].push(value);
    } else {
      paramsForGraphQL[value.areaType] = [value];
    }
  });

  // Cleanup: remove 'locations' from both parameter sets after processing.
  delete paramsForQueryString.locations;
  delete paramsForGraphQL.locations;

  // Return both sets of parameters, for query strings and GraphQL, reflecting the transformations applied.
  return { paramsForGraphQL, paramsForQueryString };
};

/**
 * Converts a URL's query parameters into an object.
 *
 * This function takes a URL string as input, parses the query parameters,
 * and constructs an object where each key corresponds to a query parameter
 * and the value is the parameter's value. If a parameter appears multiple times,
 * its values are stored in an array.
 *
 * @param {string} url - The URL containing the query parameters.
 * @returns {Object} queryObject - An object representing the query parameters.
 *
 * @example
 * const url = 'https://www.sibcycline.com/results?bathrooms=3&bedrooms=3&sortby=Newest+Listings&status=Active&status=Pending&page=1&daysSinceNew=7';
 * const queryObject = convertUrlToQueryObject(url);
 * console.log(queryObject);
 * // Output:
 * // {
 * //   bathrooms: "3",
 * //   bedrooms: "3",
 * //   sortby: "Newest Listings",
 * //   status: ["Active", "Pending"],
 * //   page: "1",
 * //   daysSinceNew: "7"
 * // }
 */
export const convertUrlToQueryObject = (url) => {
  let urlObj = new URL(url);

  let params = new URLSearchParams(urlObj.search);

  let queryObject = {};
  params.forEach((value, key) => {
    if (queryObject[key]) {
      if (Array.isArray(queryObject[key])) {
        queryObject[key].push(value);
      } else {
        queryObject[key] = [queryObject[key], value];
      }
    } else {
      queryObject[key] = value;
    }
  });

  return queryObject;
};
