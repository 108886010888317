import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import styles from '../styles.module.scss';
const { input_container, input_field, input_label, error, has_error } = styles;

// ---------------------------------------------------------

const Textarea = (props) => {
  const {
    placeholder,
    label,
    rows,
    cols,
    inputName,
    required = false,
    inputValueCallback,
    defaultInputValue
  } = props;

  const inputRef = useRef(null);
  const errorMsg = inputRef?.current?.validationMessage;
  const [inputValue, setInputValue] = useState(defaultInputValue || '');
  const [showError, setShowError] = useState(false);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setInputValue(searchTerm);

    if (inputValueCallback) {
      inputValueCallback(searchTerm);
    }
  };

  // ---------------------------------------------------------

  return (
    <div className={input_container}>
      <label className={input_label}>
        <span>{label}</span>
        <textarea
          ref={inputRef}
          className={classNames(input_field, { [has_error]: errorMsg && showError })}
          placeholder={placeholder}
          onChange={handleInputChange}
          onFocus={() => setShowError(false)}
          onBlur={() => setShowError(true)}
          value={inputValue}
          rows={rows}
          cols={cols}
          name={inputName}
          required={required}
        />
        {errorMsg && showError && <div className={error}>{errorMsg}</div>}
      </label>
    </div>
  );
};

// ---------------------------------------------------------

Textarea.propTypes = {
  cols: PropTypes.string,

  /**
   * Specifies the default value
   */
  defaultInputValue: PropTypes.string,

  inputName: PropTypes.string,
  /**
   * Specifies the value of the input field
   */
  inputValueCallback: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.string
};

export default Textarea;
