import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Image from '@components/image';
import Link from '@components/link';
import Content from '@components/content';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  cta,
  cta_background,
  cta_container,
  cta_link,
  theme_keyline,
  cta_wrapper,
  stats_container,
  content_container
} = styles;

const themeOptions = {
  keyline: theme_keyline,
  none: null
};

// ---------------------------------------------------------

const Callout = (props) => {
  const { calloutBody, image, link, placement, stat1, stat2, stat3, theme, title } = props;

  // ---------------------------------------------------------

  const statData = [stat1, stat2, stat3].filter((stat) => stat?.length > 0);

  const stats = statData.map((stat) => {
    const [title, body] = stat || [];
    return { body, title };
  });

  // ---------------------------------------------------------

  const classes = classNames(cta, {
    [cta_background]: theme === 'image',
    [themeOptions[theme]]: themeOptions[theme]
  });

  // ---------------------------------------------------------

  const linkContent = (
    <>
      {link?.label}
      {link?.icon && <Icon type="arrow" name="small-right" />}
    </>
  );

  // ---------------------------------------------------------

  return (
    <section
      className={`${classes}`}
      style={{
        backgroundImage: image?.src ? `url(${image?.src})` : null,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
      data-placement={placement}
    >
      <div className={cta_wrapper}>
        <div className={cta_container}>
          <h2>{title}</h2>

          {calloutBody && <Content className={content_container} content={calloutBody} />}
          {link?.url && (
            <Link aria-label={link?.aria} className={cta_link} url={link?.url}>
              {linkContent}
            </Link>
          )}
          {stats && (
            <ul className={stats_container}>
              {stats.map((stat, key) => (
                <li key={key}>
                  {stat.icon && <Image className={'stat_img'} image={stat.icon} />}
                  <div className={''}>
                    <h3>{stat.title}</h3>
                    <p>{stat.body}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

Callout.propTypes = {
  /**
   * Specifies the body prop
   */
  calloutBody: PropTypes.object,

  /**
   * Specifies the image src and alt text
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the link prop
   */
  link: PropTypes.shape({
    aria: PropTypes.string,
    icon: PropTypes.bool,
    label: PropTypes.string,
    url: PropTypes.string
  }),

  /**
   * Specifies the placement prop
   */
  placement: PropTypes.string,
  /**
   * Specifies the details of the stats prop
   */
  stat1: PropTypes.arrayOf(PropTypes.string),
  stat2: PropTypes.arrayOf(PropTypes.string),
  stat3: PropTypes.arrayOf(PropTypes.string),

  /**
   * Specifies the theme prop
   */
  theme: PropTypes.string,

  /**
   * Specifies the title prop
   */
  title: PropTypes.string
};

export default Callout;
