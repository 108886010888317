/* eslint-disable no-empty */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useUser } from '@auth0/nextjs-auth0';

// ---------------------------------------------------------

import Image from '@components/image';
import Icon from '@components/icon';
import Link from '@components/link';
import Navigation from './navigation';
import { Dropdown } from './navigation/dropdown';
import useWindowSize from '@lib/window-size';

// ---------------------------------------------------------

import {
  account_button,
  account_link,
  account_links,
  header,
  home_link,
  logo_container,
  logo_class,
  is_white,
  main_navigation_container,
  desktop_navigation,
  mobile_navigation,
  menu_button_container,
  menu_close_button,
  menu_open_button,
  screen_reader_title,
  navigation_container,
  navigation_header,
  navigation_is_showing,
  mobile_account_heading,
  mobile_account_menu_inner,
  no_sign_in_buttons
} from './styles.module.scss';

// ---------------------------------------------------------
import { mainNav, relocationNav, homeServicesNav } from '@data/navigation-data';

const collectionMap = {
  Main: mainNav,
  Mortgage: mainNav,
  Relocation: relocationNav,
  HomeServices: homeServicesNav,
  null: mainNav,
  undefined: mainNav
};

// ---------------------------------------------------------

const Header = ({ collection, homePath }) => {
  let { user } = useUser();
  const { main_navigation, my_account, logo } = collectionMap[collection].header || mainNav.header;

  // ---------------------------------------------------------

  const isFullNavigation = useWindowSize().width >= 1040;

  // ---------------------------------------------------------

  const [menuIsOpen, setMenu] = useState(false);

  const mobileMenuClick = () => {
    overflowHidden(!menuIsOpen);
    setMenu(!menuIsOpen);
  };

  const overflowHidden = (menuIsOpen) => {
    if (menuIsOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.removeProperty('overflow');
    }
  };

  useEffect(() => {
    overflowHidden(menuIsOpen);

    if (isFullNavigation) {
      overflowHidden(false);
      setMenu(false);
    }
  }, [menuIsOpen, isFullNavigation]);

  // ---------------------------------------------------------

  const classes = classNames(header, {
    [is_white]: collection !== 'Main',
    [navigation_is_showing]: !isFullNavigation && menuIsOpen
  });

  // ---------------------------------------------------------

  const signInButtons = my_account ? (
    <div className={account_links}>
      {!user && (
        <a className={account_link} href="/api/auth/login">
          Sign in
        </a>
      )}

      {isFullNavigation && user && (
        <Dropdown
          isFullNavigation={isFullNavigation}
          theme="my-account"
          collection={collection}
          items={my_account.subLinks}
          label="My account"
        />
      )}

      {isFullNavigation && !user && (
        <a className={account_button} href="/api/auth/signup">
          Register
        </a>
      )}
    </div>
  ) : null;

  const menuButton = () => (
    <div className={menu_button_container}>
      <button
        className={!menuIsOpen ? menu_open_button : menu_close_button}
        onClick={mobileMenuClick}
      >
        <span className={screen_reader_title}>{!menuIsOpen ? 'Menu' : 'Close'}</span>
        <Icon type="actions" name={!menuIsOpen ? 'bars' : 'close'} />
      </button>
    </div>
  );

  // ---------------------------------------------------------

  return (
    <header className={classes}>
      <div>
        <div className={logo_container}>
          <div className={mobile_navigation}>{menuButton()}</div>
          <Link
            className={classNames(logo_class, {
              [no_sign_in_buttons]: !!my_account === false
            })}
            url={homePath ? homePath : '/'}
            ariaLabel="Sibcy Cline REALTORS®"
          >
            <Image
              image={{
                src: logo.url,
                width: 149,
                height: 33
              }}
            />
          </Link>
          <div className={mobile_navigation}>{signInButtons}</div>
        </div>

        <nav
          className={classNames(navigation_container, {
            [no_sign_in_buttons]: !!my_account === false
          })}
        >
          <div className={mobile_navigation}>
            <header className={navigation_header}>
              <div>{menuButton()}</div>
              <div className={home_link}>
                <Link url={homePath ? homePath : '/'}>Home</Link>
              </div>
            </header>

            {user && (
              <div>
                <ul className={mobile_account_menu_inner}>
                  <li className={mobile_account_heading}>My account</li>
                  {my_account?.subLinks[0].children
                    .filter((item) => item.label !== 'Logout')
                    .map((item, i) => (
                      <li key={i}>
                        <Link url={item.url}>{item.label}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>

          <Navigation
            className={main_navigation_container}
            isFullNavigation={isFullNavigation}
            collection={collection}
            menuIsOpen={menuIsOpen}
            navigationLinks={main_navigation}
          />

          {user && (
            <div className={mobile_navigation}>
              <ul className={mobile_account_menu_inner}>
                {my_account?.subLinks[0].children
                  .filter((item) => item.label === 'Logout')
                  .map((item, i) => (
                    <li key={i}>
                      <a href={item.url}>{item.label}</a>
                    </li>
                  ))}
              </ul>
            </div>
          )}

          <div className={desktop_navigation}>{signInButtons}</div>
        </nav>
      </div>
    </header>
  );
};
Header.propTypes = {
  /**
   * Collection name of the core services, to indicate the header style
   */
  collection: PropTypes.oneOf([
    'Main',
    'Mortgage',
    'Insurance',
    'Relocation',
    'Title',
    'HomeServices'
  ]),

  /**
   * An array of links passed to Navigation
   */
  main_navigation: PropTypes.array,

  /**
   * An object with label + array of links passed to Navigation for My Account
   */
  my_account: PropTypes.shape({
    label: PropTypes.string,
    subLinks: PropTypes.array
  }),
  homePath: PropTypes.string
};

export default Header;
