import React from 'react';
import PropTypes from 'prop-types';
import { toKebabCase } from '@lib/utils';

// ---------------------------------------------------------

import Link from '@components/link';
import Image from '@components/image';
import Icon from '@components/icon';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  card_bottom,
  card,
  office_name,
  office_address,
  office_image,
  office_details,
  office_contact,
  contact_link
} = styles;

// ---------------------------------------------------------

const OfficeCard = (props) => {
  const { office } = props;
  const kebabSlug = toKebabCase(office.name);

  // ---------------------------------------------------------
  return (
    <div className={card}>
      <Link className={office_image} url={`/office/${encodeURIComponent(kebabSlug)}`}>
        <Image image={{ alt: office.name, height: 272, src: office.image, width: 400 }} />
      </Link>

      <div className={card_bottom}>
        <Link className={office_details} url={`/office/${encodeURIComponent(kebabSlug)}`}>
          <span className={office_name}>{office.name}</span>
          <span className={office_address}>
            {office.address} <br />
            {office.city}, {office.state} {office.zipCode}
          </span>
        </Link>
        <div className={office_contact}>
          {office.email && (
            <span className={contact_link}>
              <Link url={`mailto:${office.email}`}>
                <Icon name="email" type="actions" />
                Email
              </Link>
            </span>
          )}
          {office.phone && (
            <span className={contact_link}>
              <Link url={`tel:${office.phone}`}>
                <Icon name="phone" type="actions" />
                {office.phone}
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

OfficeCard.propTypes = {
  /**
   * Specifies the details of an office
   */
  office: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string
  })
};

export default OfficeCard;
