import React from 'react';
import Image from '@components/image';

// ---------------------------------------------------------

export default {
  copyrightText:
    'Sibcy Cline, Inc. All rights reserved. All Information is believed accurate, but is NOT guaranteed.',
  description:
    "Sibcy Cline proudly offers real estate services in Cincinnati Ohio, Northern Kentucky, Dayton Ohio and Southeast Indiana. We're one of the largest local, independent, and family-owned brokerages in the region.",
  legalLinks: [
    {
      label: 'Privacy',
      url: '/about/privacy'
    },
    {
      label: 'Accessibility',
      url: '/about/accessibility'
    },
    {
      label: 'Notices',
      url: '/about/terms-of-use'
    }
  ],
  menuLinks: [
    {
      label: 'About us',
      url: '/about'
    },
    {
      label: 'Careers',
      url: '/careers'
    },
    {
      label: 'Find an agent',
      url: '/find-an-agent'
    },
    {
      label: 'Contact us',
      url: '/contact'
    }
  ],
  rightImage: {
    image: {
      height: '60px',
      src: '/images/logos/logo-lrecotw-white@3x.png',
      width: '77px'
    },
    url: 'https://www.leadingre.com/?coid=7884'
  }
};

export const partners = {
  partnerBadges: [
    {
      image: (
        <Image
          image={{
            alt: 'Realtor Logo',
            height: '37px',
            src: '/images/logos/realtor@3x.png',
            width: '31px'
          }}
        />
      )
    },
    {
      image: (
        <Image
          image={{
            alt: 'Equal Housing Opportunity',
            height: '37px',
            src: '/images/logos/equal-housing-opportunity@3x.png',
            width: '39px'
          }}
        />
      )
    },
    {
      image: (
        <Image
          image={{
            alt: 'WQC Web Quality Certification',
            height: '40px',
            src: '/images/logos/wqcwebbadge2023@3x.png',
            width: '30px'
          }}
        />
      )
    }
  ],
  partnerLinks: [
    {
      ariaLabel: 'Respro',
      label: (
        <Image
          image={{
            height: '44px',
            src: '/images/logos/respomember-201920-white@3x.png',
            width: '45px'
          }}
        />
      ),
      url: 'https://www.respro.org/page/WhyAfBAs'
    },
    {
      ariaLabel: 'U.S. Military On The Move',
      label: (
        <Image
          image={{
            height: '29px',
            src: '/images/logos/us-military-on-the-move@3x.png',
            width: '119px'
          }}
        />
      ),
      url: 'https://relocation.sibcycline.com/military-on-the-move'
    },
    {
      ariaLabel: 'The Realty Alliance',
      label: (
        <Image
          image={{
            height: '29px',
            src: '/images/logos/therealtyalliancewhite-384@3x.png',
            width: '167px'
          }}
        />
      ),
      url: 'https://www.therealtyalliance.com/'
    }
  ]
};

export const defaultSocialLinks = [
  { icon: 'x', url: 'https://x.com/sibcycline' },
  { icon: 'facebook', url: 'https://www.facebook.com/SibcyClineRealtors' },
  { icon: 'instagram', url: 'https://www.instagram.com/sibcycline' },
  { icon: 'linkedin', url: 'https://www.linkedin.com/company/sibcy-cline' }
];
