import PropTypes from 'prop-types';

// ---------------------------------------------------------

import Carousel from './carousel';

// ---------------------------------------------------------

const TopicCarousel = (props) => {
  const carouselId = props?.sys?.id || props.carouselId;
  const showDescription = props.layout !== 'Headline Only';
  const showLink = props.layout !== 'Headline Only' && props.layout !== 'Headline + Excerpt';

  const cardsCollection = props.slidesCollection.cards.map((slide) => {
    let linkLabel;
    if (showLink) {
      linkLabel = slide.linkLabel || 'Read More';
    }

    let linkUrl = slide?.linkUrl;

    if (!linkUrl && slide?.__typename === 'PageBuilderdevelopment') {
      linkUrl =
        slide.category === 'Development' ? `/development/${slide.slug}` : `/builder/${slide.slug}`;
    } else if (!linkUrl && slide?.topic && slide?.slug) {
      if (slide.topic === 'HomeServices') {
        linkUrl = `/home-services/${slide.slug}`;
      } else {
        linkUrl = `/${slide.topic.toLowerCase()}/${slide.slug}`;
      }
    } else if (!linkUrl && slide?.__typename === 'PostBlog') {
      linkUrl = `/blog/${slide.slug}`;
    }

    if (props.theme === 'cards') {
      return {
        description: showDescription ? slide.description : null,
        image: slide.featuredImage,
        link: linkUrl && { aria: linkLabel, label: linkLabel, url: linkUrl },
        theme: 'carousel',
        title: slide.title
      };
    } else if (props.theme === 'listings') {
      return {
        ...slide,
        theme: 'carousel'
      };
    } else {
      return {
        ...slide,
        description: showDescription ? slide.description : null,
        linkLabel,
        linkUrl
      };
    }
  });

  return (
    <Carousel {...props} slidesCollection={{ cards: cardsCollection }} carouselId={carouselId} />
  );
};

TopicCarousel.propTypes = {
  carouselId: PropTypes.string,
  layout: PropTypes.string,
  slidesCollection: PropTypes.shape({
    cards: PropTypes.array
  }),
  sys: PropTypes.shape({
    id: PropTypes.string
  }),
  theme: PropTypes.oneOf(['section', 'cards', 'full-width', 'ldp', 'text-only', 'listings'])
};

export default TopicCarousel;
