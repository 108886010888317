import postsPerCategory from '@src/data/category-recents.json';

import Container from '@layout/container';
import ArticleCard from '@components/article-card';
import PropTypes from 'prop-types';
import Button from '@components/button';
import truncate from 'lodash/truncate';

import styles from './styles.module.scss';
const { blog_landing_category, blog_landing_view_all, category_title } = styles;

const Category = (props) => {
  const { title, categoryIds = [], slug } = props;

  let relatedArticles = [];

  /**
   * Fetch posts from the prebuilt 'most recent per category' data
   * Sorts one/multiple categories by most recent published data
   * Excludes and duplicates and the post itself if posted on a blog page
   */
  categoryIds.forEach((id) => {
    const categoryPosts = postsPerCategory[id].collection.filter((el) => {
      const isSamePost = el.slug === slug;
      const isDuplicate = relatedArticles.find((post) => post.slug === el.slug);
      return !isSamePost && !isDuplicate;
    });
    relatedArticles.push(...(categoryPosts || []));
  });

  const viewAllSlug = postsPerCategory[categoryIds[0]].viewAllSlug;

  const sortedResults = relatedArticles
    .sort((a, b) => Date.parse(b.publishDate) - Date.parse(a.publishDate))
    .slice(0, 3);

  relatedArticles = sortedResults.slice(0, 3);

  // ---------------------------------------------------------

  const BUTTON_THEME = 'text-only-with-caret';
  const BUTTON_LABEL = 'View all';

  const buttonProps = {
    label: BUTTON_LABEL,
    theme: BUTTON_THEME,
    url: `/blog/category/${viewAllSlug}`
  };

  // ---------------------------------------------------------

  const articles = relatedArticles.map((article, idx) => {
    const props = {
      description: truncate(article?.title, { length: 256 }),
      image: article.featuredImage,
      link: {
        aria: article.label,
        icon: true,
        label: article.label,
        url: `/blog/${article.slug}`
      },
      theme: 'primary'
    };

    return <ArticleCard key={idx} {...props} />;
  });

  // ---------------------------------------------------------

  return (
    <div>
      <Container gridOptions={{ layout: 'single' }}>
        <div className={blog_landing_category}>
          <div className={category_title}>
            <h2>{title}</h2>
          </div>
          <div className={blog_landing_view_all}>
            {buttonProps?.url && <Button {...buttonProps} />}
          </div>
        </div>
        <div>
          <Container gutter="20" layout="3">
            {articles}
          </Container>
        </div>
      </Container>
    </div>
  );
};

Category.propTypes = {
  /**
   * Unique name to match posts to their related categories
   */
  categoryIds: PropTypes.array.isRequired,

  /**
   * Post Slug to ensure no duplicate posts in related articles
   */
  slug: PropTypes.string,

  /**
   * Specifices a category title
   */
  title: PropTypes.string
};

export default Category;
