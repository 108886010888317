import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Container from '@layout/container';
import Icon from '@components/icon';
import Image from '@components/image';
import Link from '@components/link';
import PostList from '@components/post-list';

import GQL from '@lib/cms/gql';
import { MOST_RECENT_BLOGS_QUERY } from '@lib/fragments';

import styles from './styles.module.scss';
const { blog_banner, blog_banner_img, blog_cta, blog_cta_text, header_link } = styles;

const PostListWithFeatured = (props) => {
  const { referencesCollection, title } = props;
  const [latestPosts, setLatestPosts] = useState([]);

  // ---------------------------------------------------------

  useEffect(() => {
    const fetchRecentBlogs = async () => {
      const {
        data: {
          postBlogCollection: { items }
        }
      } = await GQL.fetch(MOST_RECENT_BLOGS_QUERY, { limit: 6 }, false);

      setLatestPosts(items);
    };

    if (referencesCollection?.items && referencesCollection?.items?.length > 0) {
      setLatestPosts(referencesCollection.items);
    } else {
      fetchRecentBlogs();
    }
  }, []);

  // ---------------------------------------------------------

  const featuredPost = latestPosts[0];

  // ---------------------------------------------------------

  const linkContent = (
    <>
      {featuredPost?.label}
      {featuredPost?.label && <Icon type="arrow" name={'small-right'} />}
    </>
  );

  // ---------------------------------------------------------

  return (
    <Container width="fixed" gutter="25" layout="two thirds, one third">
      <div className={blog_banner}>
        <div className={blog_banner_img}>
          <Image
            image={{ height: 500, src: featuredPost?.featuredImage?.url, width: 675 }}
            layout={'fill'}
            backgroundImage
            objectFit="cover"
            sizes="(max-width: 720px) 100vw, 675px"
            priority
            quality={75}
          />
        </div>
        <div className={blog_cta}>
          <div className={blog_cta_text}>
            <h1>{featuredPost?.title}</h1>
            {featuredPost?.slug && (
              <Link
                aria-label={featuredPost?.label}
                className={header_link}
                url={`blog/${featuredPost?.slug}`}
              >
                {linkContent}
              </Link>
            )}
          </div>
        </div>
      </div>
      <PostList referencesCollection={latestPosts.slice(1)} title={title} shouldNotFetch />
    </Container>
  );
};

PostListWithFeatured.propTypes = {
  /**
   * A collection of posts to override the default blog collection
   */
  referencesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object)
  }),

  /**
   * Specifices an a title for the list of articles
   */
  title: PropTypes.string
};

export default PostListWithFeatured;
