/* eslint-disable react/prop-types */
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';

// ---------------------------------------------------------

import Link from '@components/link';
import Icon from '@components/icon';
import Image from '@components/image';
import Content from '@components/content';

// ---------------------------------------------------------

import { Keyboard, Navigation, Pagination, Thumbs } from 'swiper/modules';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  carousel_header_content,
  contentful_wrapper,
  active,
  dot,
  dots,
  card_padded_section,
  card_wrapper_section,
  card_overlay_section,
  card_details_section,
  person_image_section,
  text_content,
  quote_text,
  author_text,
  carousel_heading_section,
  card_wrapper,
  card_image,
  carousel_heading,
  carousel_sub_header,
  carousel_link,
  carousel_container
} = styles;

import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/a11y';

// ---------------------------------------------------------

const Carousel = (props) => {
  const { slidesCollection, carouselHeader, carouselDescription, theme, background } = props;
  const description =
    typeof carouselDescription === 'string' ? (
      <p className={carousel_header_content}>{carouselDescription}</p>
    ) : (
      <Content className={contentful_wrapper} content={carouselDescription} />
    );

  const sectionSlider = (
    <>
      <Swiper
        modules={[Keyboard, Navigation, Pagination, Thumbs]}
        style={{ maxWidth: '100vw' }}
        initialSlide={0}
        spaceBetween={60}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        pagination={{
          bulletActiveClass: active,
          bulletClass: dot,
          clickable: true,
          el: `.${dots}`,
          type: 'bullets'
        }}
      >
        {slidesCollection &&
          slidesCollection.cards.map((card, index) => (
            <SwiperSlide key={index}>
              <div className={card_padded_section}>
                <div className={card_wrapper_section}>
                  <div className={card_overlay_section} />
                  <div className={card_details_section}>
                    <div className={person_image_section}>
                      {card.image && (
                        <Image
                          priority
                          image={{ height: 225, src: card.image.url, width: 159 }}
                          alt={card.image.title}
                        />
                      )}
                    </div>
                    <div className={text_content}>
                      <p className={quote_text}>{card.quote}</p>
                      <div className={author_text}>
                        {card.author && <p>{card.author}</p>}
                        {card.title && <p>{card.title}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        <div>
          <div className={dots} />
        </div>
      </Swiper>
      <div className={carousel_heading_section}>
        <h2>{carouselHeader}</h2>
        {description}
      </div>
    </>
  );

  const fullWidthSlider = (
    <>
      <div className={carousel_heading}>
        <h2>{carouselHeader}</h2>
        <div className={carousel_sub_header}>{description}</div>
      </div>
      <Swiper
        modules={[Keyboard, Navigation, Pagination, Thumbs]}
        breakpoints={{ 480: { slidesPerView: 1.1 }, 720: { slidesPerView: 1 } }}
        initialSlide={0}
        spaceBetween={20}
        slidesPerView={1.3}
        loop={true}
        pagination={{
          bulletActiveClass: active,
          bulletClass: dot,
          clickable: true,
          el: `.${dots}`,
          type: 'bullets'
        }}
      >
        {slidesCollection &&
          slidesCollection.cards.map((card, index) => (
            <SwiperSlide key={index} className={card_wrapper}>
              <div className={card_image}>
                <img alt="" src={card?.image?.url} />
              </div>
              <div className={text_content}>
                {card.description && (
                  <>
                    <h2>{card.header}</h2>
                    <p>{truncate(card.description, { length: 400 })}</p>
                    {card.link && (
                      <Link aria-label={card.linkLabel} className={carousel_link} url={card.link}>
                        {card.linkLabel}
                        {card.linkLabel && <Icon type="arrow" name={'small-right'} />}
                      </Link>
                    )}
                  </>
                )}
              </div>
            </SwiperSlide>
          ))}
        <>
          <div className={dots} />
        </>
      </Swiper>
    </>
  );

  return (
    <div className={carousel_container} data-theme={theme} data-background={background}>
      {theme === 'section' && sectionSlider}
      {theme === 'full-width' && fullWidthSlider}
    </div>
  );
};

Carousel.propTypes = {
  /**
   * Specifies the details of a carousel card
   */
  carousel: PropTypes.shape({
    carouselDescription: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
    carouselHeader: PropTypes.string,
    slidesCollection: PropTypes.shape({
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          author: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
          }),
          quote: PropTypes.string,
          title: PropTypes.string
        })
      )
    }),
    theme: PropTypes.string
  })
};

export default Carousel;
