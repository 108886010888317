import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '@components/link';
import Icon from '@components/icon';
import Image from '@components/image';

import {
  card,
  no_image,
  no_contact,
  contact_content,
  contact_link,
  inner_content_container,
  inner_content,
  image_container,
  name_heading,
  title_heading,
  company_heading
} from './styles.module.scss';

const PeopleCard = ({ fullName, professionalTitle, company, email, phone, websiteUrl, image }) => {
  const classnames = classNames(card, {
    [no_image]: !image,
    [no_contact]: !email && !phone && !websiteUrl
  });
  return (
    <div className={classnames}>
      <div className={inner_content_container}>
        {image && (
          <div className={image_container}>
            <Image image={image} alt={fullName} layout="responsive" />
          </div>
        )}
        <div className={inner_content}>
          <h2 className={name_heading}>{fullName}</h2>
          {professionalTitle && <h3 className={title_heading}>{professionalTitle}</h3>}
          {company && <h4 className={company_heading}>{company}</h4>}
        </div>
      </div>
      {(email || phone || websiteUrl) && (
        <div className={contact_content}>
          {email && (
            <span className={contact_link}>
              <Link url={`mailto:${email}`}>
                <Icon name="email" type="actions" />
                Email
              </Link>
            </span>
          )}
          {phone && (
            <span className={contact_link}>
              <Link url={`tel:${phone}`}>
                <Icon name="phone" type="actions" />
                {phone}
              </Link>
            </span>
          )}
          {websiteUrl && (
            <span className={contact_link}>
              <Link url={websiteUrl}>
                <Icon name="website" type="actions" />
                Website
              </Link>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

PeopleCard.propTypes = {
  fullName: PropTypes.string.isRequired,
  professionalTitle: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  websiteUrl: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default PeopleCard;
