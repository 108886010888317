import React from 'react';
import PropTypes from 'prop-types';

// ---------------------------------------------------------

import iconOptions from './icons';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const { svg_container } = styles;

import IconMap from './icon-map';

// ---------------------------------------------------------

const Icon = (props) => {
  let { name, type } = props;
  const iconName = `${type}-${name}`;
  const SVG = IconMap[iconName];
  if (!SVG) {
    console.warn(`Couldn't find icon named: ${iconName}`);
    return null;
  }
  return (
    <span className={svg_container}>
      <SVG title={iconName} />
    </span>
  );
};

Icon.propTypes = {
  /**
   * Specifies the name of icon
   */
  name: PropTypes.string,

  /**
   * Specifies the type of icon
   */
  type: PropTypes.oneOf(iconOptions.map((t) => t.type))
};

export default Icon;
