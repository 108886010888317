import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  checkbox_label,
  checkbox,
  contains_checkbox_with_icon,
  has_icon,
  header,
  icon_container,
  is_checked,
  layout_is_half,
  layout_is_third,
  layout_flex_row
} = styles;

const layoutOptions = {
  half: layout_is_half,
  row: layout_flex_row,
  third: layout_is_third
};

// ---------------------------------------------------------

const Checkbox = (props) => {
  let {
    onChange,
    className,
    label,
    layout,
    autoUnselectOnAll = false,
    hasIcons = false,
    items = [],
    selectAllIcon = null,
    selectAllLabel = 'Any',
    selections = []
  } = props;

  const isCheckedAll = selections?.length === 0;

  // ---------------------------------------------------------

  const layoutClass = classNames({
    [className]: className,
    [layoutOptions[layout]]: layoutOptions[layout]
  });

  // ---------------------------------------------------------

  const handleSelect = (e) => {
    const { value } = e.target;
    const valueIndex = selections.findIndex((el) => el === value);

    let newSelections = [...selections];
    if (valueIndex === -1) {
      newSelections.push(value);
    } else {
      newSelections.splice(valueIndex, 1);
    }

    if (newSelections.length === items.length && autoUnselectOnAll) {
      onChange([]);
    } else {
      onChange(newSelections);
    }
  };

  const handleSelectAll = () => {
    onChange([]);
  };

  // ---------------------------------------------------------

  return (
    <div>
      {label && <span className={header}>{label}</span>}

      <div className={layoutClass}>
        {selectAllLabel && (
          <label
            className={classNames(checkbox_label, { [contains_checkbox_with_icon]: hasIcons })}
          >
            <div
              className={classNames(checkbox, {
                [has_icon]: hasIcons,
                [is_checked]: isCheckedAll
              })}
            >
              <input
                type="checkbox"
                name={selectAllLabel}
                onChange={handleSelectAll}
                checked={isCheckedAll}
                aria-checked={isCheckedAll}
              />

              {isCheckedAll && <Icon type="actions" name="arrow" />}

              {hasIcons && selectAllIcon && (
                <div className={icon_container}>
                  <Icon type="filters" name={selectAllIcon} />
                </div>
              )}
            </div>
            <span>{selectAllLabel}</span>
          </label>
        )}

        {items.map((item, index) => {
          const itemIsChecked = selections.includes(item.value || item.label);
          return (
            <label
              className={classNames(checkbox_label, { [contains_checkbox_with_icon]: hasIcons })}
              key={index}
            >
              <div
                className={classNames(checkbox, {
                  [has_icon]: hasIcons,
                  [is_checked]: itemIsChecked
                })}
              >
                <input
                  type="checkbox"
                  name={item.label}
                  onChange={handleSelect}
                  checked={itemIsChecked}
                  aria-checked={itemIsChecked}
                  value={item.value || item.label}
                />

                {!hasIcons && itemIsChecked && <Icon type="actions" name="arrow" />}

                {hasIcons && item.icon && (
                  <div className={icon_container}>
                    <Icon type="filters" name={item.icon} />
                  </div>
                )}
              </div>
              <span>{item.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  /** When all items are checked, check the any checkbox and unselect the other checkboxes */
  autoUnselectOnAll: PropTypes.bool,

  /**
   * Specifies default selection(s) other than the 'any'
   * No items selected if no default value or 'any' value
   */
  defaultSelection: PropTypes.array,

  /**
   * Specifies if the checkboxes are using icons.
   */
  hasIcons: PropTypes.bool,

  /**
   * Specifies the checkboxes.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Specifies the icon being used in the checkbox. Defaults to checkmark.
       */
      icon: PropTypes.string,

      /**
       * Specifies if the item is checked onload.
       */
      isChecked: PropTypes.bool,

      /**
       * Specifiesa key for the checkbox.
       */
      key: PropTypes.string,

      /**
       * Specifies the label of the checkbox.
       */
      label: PropTypes.string,

      /**
       * Specifies the value of the checkbox.
       */
      value: PropTypes.any
    })
  ),

  /**
   * An optional label to appear above the checkboxes
   */
  label: PropTypes.string,

  /**
   * Specifies the icon of the 'any' checkbox option that clears the filter. Defaults to checkmark.
   */
  layout: PropTypes.oneOf(Object.keys(layoutOptions)),

  /**
   * Updates the items that are checked when the selected options change
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Specifies the icon of the 'any' checkbox option that clears the filter. Defaults to checkmark.
   */
  selectAllIcon: PropTypes.string,

  /**
   * Specifies the label of the 'any' checkbox option that clears the filter. Defaults to "Any".
   */
  selectAllLabel: PropTypes.string,

  /**
   * Specifies the items that are selected (array of values)
   */
  selections: PropTypes.arrayOf(PropTypes.string)
};

export default Checkbox;
