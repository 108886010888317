import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@src/components/icon';
import Link from '@src/components/link';

// ---------------------------------------------------------

import { contains_arrow_icons, link_list } from './styles.module.scss';

// ---------------------------------------------------------

const LinkList = ({ className, containsArrowIcon, links = [] }) => {
  const classes = classNames(link_list, {
    [className]: className,
    [contains_arrow_icons]: containsArrowIcon
  });

  return (
    <ul className={classes}>
      {links?.map((item, index) => {
        return (
          <li key={index}>
            <Link className={item.className} ariaLabel={item.ariaLabel} url={item.url}>
              {item.label}
              {containsArrowIcon && <Icon type="arrow" name="small-right" />}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

LinkList.propTypes = {
  /**
   * Specifies if each link contains an arrow icon
   */
  containsArrowIcon: PropTypes.bool,

  /**
   * Specifies an array of links
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default LinkList;
