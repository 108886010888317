import PropTypes from 'prop-types';
import classNames from 'classnames';
import { startCase } from 'lodash';
import useWindowSize from '@lib/window-size';

// ---------------------------------------------------------

import Image from '@components/image';
import LikeButton from '@components/like-button';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  card_bottom,
  card_is_media,
  card_is_small,
  card_top,
  card,
  property_address,
  property_details_container,
  property_details,
  property_image,
  property_price,
  property_realtor_container,
  property_realtors,
  property_status_sold,
  property_status,
  on_market_date,
  theme_property,
  pending_alert
} = styles;

const themeOptions = {
  property: theme_property
};

// ---------------------------------------------------------

const Card = (props) => {
  let { property, image, theme, url } = props;

  const copyrightImage = property?.mlsCopyright?.logoUrl;

  // ---------------------------------------------------------

  const smallVersion = useWindowSize().width <= 180;
  const themeMedia = theme === 'media';

  // ---------------------------------------------------------

  const classes = classNames(card, {
    [themeOptions[theme]]: themeOptions[theme],
    [card_is_media]: themeMedia,
    [card_is_small]: smallVersion
  });

  // ---------------------------------------------------------

  const isActiveSibcyAgent = property?.realtor?.isActiveSibcyAgent;

  const realtorInfo = (
    <div className={property_realtor_container}>
      {isActiveSibcyAgent ? (
        <Image
          layout="fixed"
          image={{
            height: '25',
            src: '/images/logos/sibcy-logo-card-small@3x.png',
            width: '44'
          }}
        />
      ) : copyrightImage ? (
        <Image
          layout="fixed"
          image={{
            height: 18,
            url: copyrightImage,
            width: copyrightImage.includes('logos/IDXReciprocity/IDX') ? 30 : 75
          }}
        />
      ) : (
        <Image
          layout="fixed"
          image={{
            height: '22',
            url: '/images/logos/broker-reciprocity@3x.png',
            width: '61'
          }}
        />
      )}

      {isActiveSibcyAgent && (
        <span className={property_realtors}>
          {property.realtor.primaryRealtorName && property.realtor.primaryRealtorName}

          {property.realtor.secondaryRealtorName && (
            <span>with {property.realtor.secondaryRealtorName}</span>
          )}
        </span>
      )}
    </div>
  );

  // ---------------------------------------------------------

  const comingSoonAlert = property?.alerts?.filter((alert) => alert.type === 'ComingSoon');

  const pendingAlert = property?.alerts?.filter((alert) => alert.type === 'Pending');

  // ---------------------------------------------------------

  let cardComponent = (
    <>
      {!smallVersion && !themeMedia && <LikeButton id={property.listingId} />}

      <a href={url}>
        <div className={card_top}>
          <Image
            className={property_image}
            image={{ height: 272, src: image.src, width: 400 }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            objectFit="cover"
          />

          {property.alertPills && !smallVersion && !themeMedia && (
            <ul className={property_status}>
              {property.alertPills?.map((stat, index) => {
                return (
                  <li key={`card-alert-${property.listingId}-${index}`}>
                    <span className={startCase(stat) === 'Sold' ? property_status_sold : null}>
                      {startCase(stat)}
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className={card_bottom}>
          <div className={property_details_container}>
            <div>
              <span className={property_price}>{property.price}</span>
              {comingSoonAlert?.length > 0 && (
                <span className={on_market_date}>{comingSoonAlert[0]?.text}</span>
              )}
              {pendingAlert?.length > 0 && (
                <span className={pending_alert}>{pendingAlert[0]?.text}</span>
              )}
              <span className={property_address}>
                {property.address}
                <span>
                  {property.city}, {property.state} {property.zip}
                </span>
              </span>
            </div>

            {property.details && !themeMedia && (
              <div className={property_details}>
                <ul>
                  {property.details.map((detail, index) => {
                    let value = Object.values(detail)[0];

                    if (value > 0 || typeof value === 'string') {
                      return (
                        <li key={index}>
                          {value} {Object.keys(detail)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            )}
          </div>

          {!smallVersion && !themeMedia && realtorInfo}
        </div>
      </a>
    </>
  );

  return <div className={classes}>{cardComponent}</div>;
};

Card.propTypes = {
  /**
   * Specifies if the property is Coming Soon
   */
  comingSoon: PropTypes.bool,

  /**
   * Specifies the image src and alt text
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the details of a property
   */
  property: PropTypes.shape({
    address: PropTypes.string,
    alertPills: PropTypes.array,
    alerts: PropTypes.array,
    city: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        ac: PropTypes.number,
        ba: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        br: PropTypes.number
      })
    ),
    listingId: PropTypes.number,
    mlsCopyright: PropTypes.shape({
      logoUrl: PropTypes.string
    }),
    onMarketDate: PropTypes.any,
    price: PropTypes.string,
    realtor: PropTypes.shape({
      isActiveSibcyAgent: PropTypes.bool,
      primaryRealtorName: PropTypes.string,
      secondaryRealtorName: PropTypes.string
    }),
    state: PropTypes.string,
    zip: PropTypes.string
  }),

  /**
   * Specifies the theme of the card
   */
  theme: PropTypes.string,

  /**
   * Specifies the url if the whole card is clickable
   */
  url: PropTypes.string
};

export default Card;
