import React, { useState } from 'react';
import officeList from '@src/data/office-list.json';

// ---------------------------------------------------------

import Grid from '@layout/grid';
import OfficeCard from '@components/office-card';
import Select from '@components/select';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const { container, search_bar, state_sort, zero_state } = styles;

// ---------------------------------------------------------

const SearchByOffice = () => {
  const [servingArea, setServingArea] = useState('');

  const getOfficeData = () => {
    if (officeList.length > 0) {
      const officeArr = [...officeList];

      const corpIndex = officeArr.findIndex((item) => item.officeId === 123);

      if (corpIndex !== -1) {
        let item = officeArr[corpIndex];

        officeArr.splice(corpIndex, 1);
        officeArr.unshift(item);
      }
      return officeArr;
    }
  };

  const filteredOffices = () => {
    return getOfficeData().filter((office) => {
      const areaMatch = servingArea === '' ? true : office.statesServing.includes(servingArea);

      return areaMatch;
    });
  };

  // ---------------------------------------------------------

  const selectServingArea = (el) => {
    if (el === 'all') {
      return setServingArea('');
    }

    setServingArea(el);
  };

  // ---------------------------------------------------------

  return (
    <div className={container}>
      <div className={search_bar}>
        <div className={state_sort}>
          <Select
            options={[
              { label: 'Any Location', value: 'all' },
              { label: 'Ohio', value: 'Ohio' },
              { label: 'Kentucky', value: 'Kentucky' },
              { label: 'Indiana', value: 'Indiana' }
            ]}
            onChange={(el) => selectServingArea(el)}
            label="Serving"
            selected={servingArea}
          />
        </div>
      </div>

      {officeList.length > 0 ? (
        <Grid layout="third">
          {filteredOffices().map((office, idx) => {
            const officeName = office.name === 'Home' ? 'Corporate Office' : office.name;
            const email = office?.agents[0]?.agent?.email;
            return (
              <div key={`${office.name}-${idx}`}>
                <OfficeCard
                  office={{
                    address: office.address,
                    city: office.city,
                    email: email,
                    image: office.photoUrl,
                    name: officeName,
                    phone: office.phone,
                    state: office.state,
                    zipCode: office.zipCode
                  }}
                />
              </div>
            );
          })}
        </Grid>
      ) : (
        <div className={zero_state}>
          <p>No Offices match your search criteria.</p>
        </div>
      )}
    </div>
  );
};

SearchByOffice.propTypes = {};

export default SearchByOffice;
