import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Button from '@components/button';
import Image from '@components/image';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  jumbotron_banner,
  jumbotron_banner_bar,
  jumbotron_banner_buttons,
  jumbotron_banner_card,
  jumbotron_banner_centered,
  jumbotron_banner_left_centered,
  jumbotron_banner_content,
  jumbotron_banner_cta,
  button_left_aligned,
  jumbotron_banner_image,
  jumbotron_banner_wrapper,
  jumbotron_simple
} = styles;

// ---------------------------------------------------------

const Jumbotron = (props) => {
  const { body, image, theme = 'card', title, ctas = [] } = props;

  // ---------------------------------------------------------

  const classes = classNames(jumbotron_banner, {
    [jumbotron_banner_bar]: theme === 'bar',
    [jumbotron_banner_card]: theme === 'card',
    [jumbotron_banner_centered]: theme === 'centered',
    [jumbotron_banner_left_centered]: theme === 'left-centered',
    [jumbotron_simple]: theme === 'simple'
  });

  // ---------------------------------------------------------

  const ctaBanner = (
    <div className={jumbotron_banner_cta}>
      <div className={jumbotron_banner_wrapper}>
        <div className={jumbotron_banner_content}>
          {theme === 'bar' && <p>{body}</p>}
          <h1>{title}</h1>
          {theme !== 'bar' && <p>{body}</p>}
          {ctas.length > 0 && (
            <div
              className={classNames(
                jumbotron_banner_buttons,
                (title === 'Premier Builders' || title === 'New Developments') &&
                  button_left_aligned
              )}
            >
              {(ctas || []).map((cta, idx) => (
                <Button
                  key={`jumboCta-${idx}`}
                  label={cta.label}
                  theme={cta.theme}
                  className={cta.className}
                  type="button"
                  url={cta.url}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <section className={`${classes} ${theme}`}>
      <div className={jumbotron_banner_image}>
        <Image
          image={{
            alt: image?.alt,
            height: image?.height,
            src: image?.src || image?.url,
            width: image?.width
          }}
          backgroundImage
          objectFit={'cover'}
          layout={'fill'}
          priority
          quality={75}
          sizes="100vw"
        />
      </div>
      {ctaBanner}
    </section>
  );
};

Jumbotron.propTypes = {
  /**
   * Specifies the details of the cta
   */
  body: PropTypes.string,

  /**
   * Array of max two buttons to display on the jumbotron
   */
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      theme: PropTypes.string,
      url: PropTypes.string
    })
  ),

  /**
   * Specifies the image src and alt text
   */
  image: PropTypes.object,

  theme: PropTypes.string,

  title: PropTypes.string
};

export default Jumbotron;
