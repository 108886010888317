import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toIsoString, slugify } from '@lib/utils';

// --------------------------------------------------------

import { captchaVerify } from '@lib/utils';
import Button from '@components/button';
import Image from '@components/image';
import Toast from '@components/toast';
import Content from '@components/content';

// ---------------------------------------------------------

import Input from '@components/inputs/input';
import Radio from '@components/radio-button';
import Textarea from '@components/inputs/textarea';
import Checkbox from '@components/checkbox';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  theme_no_background,
  theme_no_background_no_padding,
  theme_office,
  form_wrapper,
  form_bg_img,
  form_container,
  form_inside_container,
  form,
  form_title,
  form_row,
  form_support_block,
  form_image,
  form_support_block_text,
  form_success,
  error_style,
  previous_step,
  disclaimer
} = styles;

const themeOptions = {
  'no-background': theme_no_background,
  'no-background-no-padding': theme_no_background_no_padding,
  none: null,
  office: theme_office
};

// ---------------------------------------------------------

const Forms = ({
  backgroundImage,
  supportImage,
  header,
  formDescription,
  endpoint,
  category,
  endpointId,
  communityName,
  agentId,
  communityState,
  linkLabel,
  theme,
  supportDescription,
  defaultFormValue,
  supportHeader,
  setModalIsOpen,
  blogPath,
  blogTitle,
  emailRequired,
  phoneNumberRequired,
  workingWithAgentField,
  nextStepCallback,
  fullNameRequestModal,
  fullNameRequestModalCallback,
  email,
  phoneNumber,
  agent,
  agentCallback,
  associatedAgentId,
  loggedInUserId,
  phoneNumberCallback,
  emailCallback,
  date,
  details,
  availability,
  time,
  title,
  selected,
  mailTo,
  subject,
  className,
  consentToBeContactedByAgent
}) => {
  const router = useRouter();
  const [slug, setSlug] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [checkboxSelections, setCheckboxSelections] = useState([]);
  const { userInfo } = useSelector((state) => state.user);
  let fullName = '';

  useEffect(() => {
    if (!router.isReady) return;
    setSlug(endpointId || router.query.slug);
  }, [router.isReady]);

  useEffect(() => {
    if (endpoint === 'request a tour' && submitted === 'success') {
      const values = [];
      values['date'] = date;
      values['details'] = details;
      values['time'] = time;
      values['availability'] = availability;
      values['selected'] = selected;
      values['expiration'] = new Date().getTime() + 60 * 60 * 24 * 1000;
      localStorage.setItem('request a tour', JSON.stringify(values));
      nextStepCallback(3);
    } else if (endpoint === 'request a tour' && submitted === 'error') {
      setModalIsOpen(false);
    } else if (endpoint === 'open house' && submitted === 'success') {
      nextStepCallback(2);
    } else if (endpoint === 'quickbuy' && submitted === 'success') {
      setModalIsOpen(false);
    } else {
      return;
    }
  }, [submitted]);

  if (userInfo?.given_name && userInfo?.family_name) {
    fullName = `${userInfo?.given_name} ${userInfo?.family_name}`;
  }

  const isoString = toIsoString(date, time);

  const clickHandler = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries());
    const captchaToken = await captchaVerify();

    setDisabled(true);

    if (!captchaToken) {
      setErrorMessage('There was a problem with the captcha. Please try again.');
      setDisabled(false);
      return;
    }

    if (values.email === '' && values.phoneNumber === '') {
      setErrorMessage('Please enter an email or phone number.');
      setDisabled(false);
      return;
    } else {
      setErrorMessage(false);
    }

    // Include the captcha token in the values under the key 'g-recaptcha-response'
    values['g-recaptcha-response'] = captchaToken;

    await axios({
      data: {
        agentId: Number(agentId) || 0,
        blogPath,
        blogTitle,
        category,
        communityName,
        endpoint,
        endpointId: endpointId || slug,
        state: communityState,
        title,
        location: values?.location || '',
        values: {
          ...values,
          agentId: Number(agentId) || 0,
          associatedAgentId: Number(associatedAgentId) || 0,
          comments: workingWithAgentField ? details : values.comments || '',
          confirmVia: selected,
          emailSubject: subject || '',
          emailTo: mailTo || '',
          isLoggedIn: loggedInUserId ? true : false,
          loggedInUserId: Number(loggedInUserId) || 0,
          looseTime: availability === 'Specific Date/Time' ? '' : availability,
          specificTime: isoString || null,
          workingWithAgent: agent?.name || agent || '',
          hasGivenConsent: !!checkboxSelections.includes('consentToBeContactedByAgent') || null
        }
      },
      method: 'POST',
      url: '/api/forms'
    })
      .then((res) => {
        if (res.status === 200) {
          setDisabled(false);

          Toast.notify({
            message: 'We have received your request.',
            type: 'success'
          });
          setSubmitted('success');
        }
      })
      .catch((error) => {
        setDisabled(false);

        Toast.notify({
          message: `Failed to submit form.`,
          type: 'danger'
        });

        // Submit error report to rollbar on form failure
        if (window.Rollbar) {
          window.Rollbar.error(error);
        } else {
          console.error('Rollbar (inactive) Error:', error);
        }

        setSubmitted('error');
      });
  };

  // ---------------------------------------------------------

  const classes = classNames(form_wrapper, {
    [themeOptions[theme]]: themeOptions[theme],
    [className]: className
  });

  const formData = [
    [
      {
        defaultInputValue: fullNameRequestModal || fullName,
        defaultOnChange: fullNameRequestModalCallback,
        inputName: 'fullName',
        label: 'Full Name',
        required: true,
        type: 'input'
      }
    ],
    [
      {
        defaultInputValue: email || userInfo?.email || '',
        defaultOnChange: emailCallback,
        inputName: 'email',
        inputType: 'email',
        label: 'Email',
        required: emailRequired || false,
        type: 'input'
      },
      {
        defaultInputValue: phoneNumber,
        defaultOnChange: phoneNumberCallback,
        inputName: 'phoneNumber',
        inputType: 'tel',
        label: 'Phone',
        required: phoneNumberRequired || false,
        type: 'input'
      }
    ],
    endpoint !== 'quickbuy' && [
      workingWithAgentField
        ? {
            defaultInputValue: agent?.name || agent,
            defaultOnChange: agentCallback,
            inputName: 'workingWithAgent',
            label: 'Are you working with an agent?',
            placeholder: 'Agent Name, Broker',
            required: false,
            type: 'input'
          }
        : {
            defaultInputValue: defaultFormValue || '',
            inputName: 'comments',
            label: 'Message',
            required: true,
            rows: '4',
            type: 'textarea'
          }
    ],
    endpoint === 'quickbuy' && [
      {
        defaultInputValue: defaultFormValue || '',
        inputName: 'location',
        label: 'Property Address or City/Suburb',
        required: true,
        rows: '4',
        type: 'input'
      }
    ],
    (endpoint === 'open house' || consentToBeContactedByAgent) && [
      {
        inputName: 'consentToBeContactedByAgent',
        required: true,
        type: 'checkbox',
        selections: checkboxSelections,
        onChange: (e) => {
          setCheckboxSelections(e);
        },
        items: [
          {
            label: 'I consent to be contacted by an agent.',
            value: 'consentToBeContactedByAgent',
            isChecked: false
          }
        ],
        selectAllLabel: null
      }
    ]
  ];

  // ---------------------------------------------------------

  const inputType = (item, index, key) => {
    switch (item.type) {
      case 'input':
        return <Input key={`${key}-${index}`} {...item} />;
      case 'radio':
        return <Radio key={`${key}-${index}`} {...item} />;
      case 'textarea':
        return <Textarea key={`${key}-${index}`} {...item} />;
      case 'checkbox':
        return <Checkbox key={`${key}-${index}`} {...item} />;
      default:
        return <></>;
    }
  };

  // ---------------------------------------------------------

  return (
    <section className={classes}>
      {backgroundImage?.src && (
        <div className={form_bg_img}>
          <Image backgroundImage image={backgroundImage} layout="fill" objectFit="cover" />
        </div>
      )}
      <div className={form_container}>
        <div className={form_inside_container}>
          {submitted === 'success' ? (
            <div className={form_success}>
              <div>Thank you for your interest.</div>
              <div>Your request has been received.</div>
            </div>
          ) : (
            <form className={form} onSubmit={clickHandler}>
              {header && (
                <legend id={slugify(header)} className={form_title}>
                  {header}
                </legend>
              )}
              {formDescription &&
                (formDescription?.json ? (
                  <Content content={formDescription} />
                ) : (
                  <p>{formDescription}</p>
                ))}
              {formData
                .filter((data) => data)
                ?.map((row, key) => (
                  <fieldset
                    className={form_row}
                    key={key}
                    data-flex={row[0]?.type === 'radio' ? 'row' : ''}
                  >
                    {row.map((item, index) => inputType(item, index, key))}
                  </fieldset>
                ))}
              <div>
                <Button label={linkLabel} type="submit" disabled={disabled} />
              </div>
              {errorMessage && <p className={error_style}>{errorMessage}</p>}
              {workingWithAgentField && endpoint === 'request a tour' && (
                <button
                  type="button"
                  className={previous_step}
                  onClick={(e) => {
                    e.preventDefault();
                    nextStepCallback(1);
                  }}
                >
                  &#60; Go back to previous page
                </button>
              )}

              <p className={disclaimer}>
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </p>
            </form>
          )}
        </div>
        {(supportHeader || supportDescription) && (
          <div className={form_support_block}>
            <div>
              {supportImage && (
                <div className={form_image}>
                  <Image
                    image={{
                      ...supportImage
                    }}
                    objectFit="contain"
                  />
                </div>
              )}
              <div className={form_support_block_text}>
                <h2>{supportHeader}</h2>
                {supportDescription &&
                  (supportDescription?.json ? (
                    <Content content={supportDescription} />
                  ) : (
                    <p>{supportDescription}</p>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

Forms.propTypes = {
  /**
   * Specifies the name of the Content type coming from Contentful
   */
  __typename: PropTypes.string,

  /**
   * Specifies the assosciated agent
   */
  agent: PropTypes.object,

  /**
   * Function that sets the assosciated agent
   */
  agentCallback: PropTypes.func,

  /**
   * Specifies the agent ID
   */
  agentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Specifies the LoggedIn user's associatedAgentID
   */
  associatedAgentId: PropTypes.string,

  /**
   * Specifies the availability on the request a tour modal
   */
  availability: PropTypes.string,

  /**
   * Specifies the background image src and alt text
   */
  backgroundImage: PropTypes.shape({
    src: PropTypes.string
  }),

  /**
   * Specified the blog path
   */
  blogPath: PropTypes.string,

  /**
   * Specified the blog title
   */
  blogTitle: PropTypes.string,

  /**
   * Specifies the builder/development category
   */
  category: PropTypes.string,

  /**
   * Specifies the community name
   */
  communityName: PropTypes.string,

  /**
   * Specifies the community state
   */
  communityState: PropTypes.string,

  /**
   * Specifies the date on the request a tour modal
   */
  date: PropTypes.string,

  /**
   * Specifies the default value for the text area
   */
  defaultFormValue: PropTypes.string,

  /**
   * Specifies the details on the request a tour modal
   */
  details: PropTypes.string,

  /**
   * Specifies the user's email
   */
  email: PropTypes.string,

  /**
   * Function that sets the user's email
   */
  emailCallback: PropTypes.func,

  /**
   * Specifies if the email input is required
   */
  emailRequired: PropTypes.bool,

  /**
   * Specifies the endpoint to send the data to
   */
  endpoint: PropTypes.string,

  /**
   * Specifies the id value for the endpoint if this can't be taken from the slug
   */
  endpointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Specifies the description on the form
   */
  formDescription: PropTypes.oneOfType([
    PropTypes.shape({
      json: PropTypes.object
    }),
    PropTypes.string
  ]),

  /**
   * The fullname value that comes from the request a tour modal
   */
  fullNameRequestModal: PropTypes.string,

  /**
   * Function that sets the fullname value that comes from the request a tour modal
   */
  fullNameRequestModalCallback: PropTypes.func,

  /**
   * Specifies the heading on the form
   */
  header: PropTypes.string,

  /**
   * Specifies the submit button label on the form
   */
  linkLabel: PropTypes.string,

  /**
   * Specifies the LoggedIn user's Id
   */
  loggedInUserId: PropTypes.string,

  /**
   * Specifies the emailTo string
   */
  mailTo: PropTypes.string,

  /**
   * Specifies the state for the full name input
   */
  nameState: PropTypes.string,

  /**
   * Function that sets the request a tour modal to the step indicated
   */
  nextStepCallback: PropTypes.func,

  /**
   * Specifies the user's phone number
   */
  phoneNumber: PropTypes.string,

  /**
   * Function that sets the user's phoneNumber
   */
  phoneNumberCallback: PropTypes.func,

  /**
   * Specifies is the phoneNumber input is required
   */
  phoneNumberRequired: PropTypes.bool,

  /**
   * Specifies the checked array on the request a tour modal
   */
  selected: PropTypes.array,

  /**
   * Function to toggle modal state.
   */
  setModalIsOpen: PropTypes.func,

  /**
   * Specified the email subject
   */
  subject: PropTypes.string,

  /**
   * Specifies the description on the support Block
   */
  supportDescription: PropTypes.oneOfType([
    PropTypes.shape({
      json: PropTypes.object
    }),
    PropTypes.string
  ]),

  /**
   * Specifies the heading on the support Block
   */
  supportHeader: PropTypes.string,

  /**
   * Specifies the support image src and alt text
   */
  supportImage: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    src: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),

  /**
   * Specifies the theme.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the time on the request a tour modal
   */
  time: PropTypes.string,

  /**
   * Specifies the title
   */
  title: PropTypes.string,

  /**
   * Specifies if the workingWithAgentField is being used in the form
   */
  workingWithAgentField: PropTypes.bool,

  /**
   * Specifies if the consentToBeContactedByAgent is being used in the form
   */
  consentToBeContactedByAgent: PropTypes.bool
};

export default Forms;
