import classNames from 'classnames';
import PropTypes from 'prop-types';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Image from '@components/image';
import Link from '@components/link';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const { square, square_image, squares_container, theme_1x4, theme_2x2 } = styles;

const themeOptions = {
  '1x4': theme_1x4,
  '2x2': theme_2x2
};

// ---------------------------------------------------------

const Quad = (props) => {
  const { squares, theme } = props;

  // -------------------------------------------------------

  const classes = classNames(squares_container, {
    [themeOptions[theme]]: themeOptions[theme]
  });

  // -------------------------------------------------------

  return (
    <div className={classes}>
      {squares?.items?.map((item, key) => {
        let { url, icon, title, label } = item;

        return (
          <Link className={square} url={url} key={key}>
            <div>
              <Image
                className={square_image}
                image={{ height: 64, url: icon?.image?.url, width: 64 }}
              />
              <h3>{title}</h3>
            </div>

            <span>
              {label}
              <Icon type="arrow" name={'small-right'} />
            </span>
          </Link>
        );
      })}
    </div>
  );
};

Quad.propTypes = {
  /**
   * Specifies the props of the square
   */
  squares: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        link: PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string
        }),
        title: PropTypes.string
      })
    )
  }),

  /**
   * Specifies the theme.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions))
};

export default Quad;
