import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  grid,
  gutter_0,
  gutter_12,
  gutter_15,
  gutter_20,
  gutter_25,
  gutter_30,
  gutter_50,
  vertical_top,
  vertical_center,
  vertical_bottom,
  layout_is_half,
  layout_is_one_third_two_thirds,
  layout_is_third,
  layout_is_two_thirds_one_third
} = styles;

// ---------------------------------------------------------

import Components from '@layout/components';

// ---------------------------------------------------------

const gutterOptions = {
  0: gutter_0,
  12: gutter_12,
  15: gutter_15,
  20: gutter_20,
  25: gutter_25,
  30: gutter_30,
  50: gutter_50
};

const layoutOptions = {
  2: layout_is_half,
  3: layout_is_third,
  half: layout_is_half,
  'one third, two thirds': layout_is_one_third_two_thirds,
  single: 'layout-is-single',
  third: layout_is_third,
  'two thirds, one third': layout_is_two_thirds_one_third
};

const verticalAlignmentOptions = {
  bottom: vertical_bottom,
  center: vertical_center,
  top: vertical_top
};

// ---------------------------------------------------------

const Grid = (props) => {
  let { children, className, components, gutter = '15', verticalAlignment = 'top', layout } = props;

  const hasChildren = React.Children.count(children) > 0;
  const layoutIsSingle = layout === 'single';

  if (!layout && hasChildren) layout = children.length;
  if (!layout && components) layout = components.length;

  // ---------------------------------------------------------

  const classes = classNames({
    [className]: className,
    [grid]: grid,
    [verticalAlignmentOptions[verticalAlignment]]:
      !layoutIsSingle && verticalAlignmentOptions[verticalAlignment],
    [gutterOptions[gutter]]: gutterOptions[gutter],
    [layoutOptions[layout]]: layoutOptions[layout]
  });

  // ---------------------------------------------------------

  const multipleOf3 = (n) => n % 3 === 0;

  // ---------------------------------------------------------

  return (
    <div className={classes}>
      {hasChildren
        ? children
        : components &&
          components.map((component, index) => {
            return <Components key={index} gridLayout={layout} {...component} />;
          })}

      {(layout === 'third' || layout === '3') &&
        !multipleOf3(hasChildren ? children.length : components?.length) && <div />}
    </div>
  );
};

Grid.propTypes = {
  /**
   * Contains components that get passed on to the <Grid />.
   */
  children: PropTypes.node,

  /**
   * An array of components that get passed on to <Component />
   */
  components: PropTypes.arrayOf(PropTypes.object),

  /**
   * Specifies the size of the grid's gutter.
   */
  gutter: PropTypes.oneOf(Object.keys(gutterOptions)),

  /**
   * Specifies the width of each child in the grid on larger screens
   */
  layout: PropTypes.oneOf(Object.keys(layoutOptions)),

  /**
   * Specifies the vertical alignment of the components within the grid
   */
  verticalAlignment: PropTypes.oneOf(Object.keys(verticalAlignmentOptions))
};

export default Grid;
