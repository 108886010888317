/* eslint-disable no-undef */

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  sell_with_sibcy_background_image,
  sell_with_sibcy,
  sell_with_sibcy_container,
  buyside_widget_container
} = styles;

// ---------------------------------------------------------

import Image from '@components/image';
import BuysideWidget from '@components/buyside-widget';
import Window from '@lib/window-size';

// ---------------------------------------------------------

const SellWithSibcy = () => {
  const isMobile = Window().width < 720;

  // ---------------------------------------------------------

  return (
    <div className={sell_with_sibcy}>
      <div className={sell_with_sibcy_background_image}>
        <Image
          layout="fill"
          objectFit="cover"
          backgroundImage
          image={{
            height: isMobile ? 383 : 450,
            src: `/images/sws-background-${isMobile ? 'm' : 'd'}.jpg`,
            width: isMobile ? 787 : 653
          }}
        />
      </div>

      <div className={sell_with_sibcy_container}>
        <h2>Sell with Sibcy Cline</h2>
        <p>
          Enter your address for a free market report on your home. Explore your home value
          estimate, buyer heatmap, supply-side trends, and more.
        </p>
        <div className={buyside_widget_container}>
          <BuysideWidget></BuysideWidget>
        </div>
      </div>
    </div>
  );
};

SellWithSibcy.propTypes = {};

export default SellWithSibcy;
