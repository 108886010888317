import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Image from '@components/image';
import Link from '@components/link';

// ---------------------------------------------------------

import styles from './styles.module.scss';
const {
  article_card_container,
  article_card_content,
  article_card_top,
  article_card,
  article_eyebrow,
  article_image,
  article_link,
  description_text,
  icon_container,
  overlay,
  theme_carousel,
  theme_community,
  theme_cta,
  theme_primary
} = styles;

const themeOptions = {
  carousel: theme_carousel,
  community: theme_community,
  cta: theme_cta,
  primary: theme_primary
};

// ---------------------------------------------------------

const ArticleCard = (props) => {
  const { eyebrow, icon, image, link, description, theme = 'cta', title } = props;

  // -------------------------------------------------------

  const classes = classNames(article_card_container, {
    [themeOptions[theme]]: themeOptions[theme]
  });

  const sizes =
    theme === 'carousel'
      ? // assuming there is at least 3 cards in the carousel
        '(max-width: 960px) 400px, 305px'
      : '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw';

  // ---------------------------------------------------------

  const articleCardInner = (
    <div className={classes}>
      <div className={article_card_top}>
        {image && (
          <Image
            className={article_image}
            objectFit="cover"
            backgroundImage
            image={image}
            layout="fill"
            sizes={sizes}
          />
        )}

        {icon && <div className={overlay}></div>}

        {icon && (
          <span className={icon_container}>
            <Image image={icon} layout="intrinsic" />
          </span>
        )}
      </div>

      <div className={article_card_content}>
        {eyebrow && <span className={article_eyebrow}>{eyebrow}</span>}
        {title && <h2>{truncate(title, { length: 100 })}</h2>}

        <p className={description_text}>{truncate(description, { length: 150 })}</p>

        {link.label && link.url && (
          <span className={article_link}>
            {link?.label}
            {link?.label && <Icon type="arrow" name={'small-right'} />}
          </span>
        )}
      </div>
    </div>
  );

  return link?.url ? (
    <Link aria-label={link?.aria} className={article_card} url={link?.url} data-style={theme}>
      {articleCardInner}
    </Link>
  ) : (
    <div className={article_card} data-style={theme}>
      {articleCardInner}
    </div>
  );
};

ArticleCard.propTypes = {
  /**
   * Specifies the body of the card
   */
  description: PropTypes.string,

  /**
   * Specifies the eyebrow prop
   */
  eyebrow: PropTypes.string,

  /**
   * Specifies the icon prop
   */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the image prop
   */
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the link prop
   */
  link: PropTypes.shape({
    aria: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string
  }),

  /**
   * Specifies the theme prop
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the title prop (also used as location)
   */
  title: PropTypes.string
};

export default ArticleCard;
