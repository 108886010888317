import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';

// ---------------------------------------------------------

import { social_nav } from './styles.module.scss';

// ---------------------------------------------------------

const SocialNav = ({ links, className }) => (
  <nav className={classNames(social_nav, className)}>
    {links?.map((link, index) => (
      <Link url={link.url} key={index} ariaLabel={link.icon}>
        <Icon name={link.icon} type="social" />
      </Link>
    ))}
  </nav>
);

SocialNav.propTypes = {
  /**
   * Specifies an array of social links.
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SocialNav;
